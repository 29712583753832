$(document).ready(function () {

	jQuery.each(jQuery('[data-touch]'), function (e) {
		var $this = $(this);
		var status = $this.data('touch');

		var $window = $(this);

		if ($window.width() < 768) {
			$this.removeClass('no-touch').addClass('touch');
			$this.attr('data-touch', true);
		} else {
			$this.addClass('no-touch').removeClass('touch');
			$this.attr('data-touch', false);
		}

		$(window).on('resize', function () {
			var $window = $(this);

			if ($window.width() < 768) {
				$this.removeClass('no-touch').addClass('touch');
				$this.attr('data-touch', true);
			} else {
				$this.addClass('no-touch').removeClass('touch');
				$this.attr('data-touch', false);
			}
		});
	});

	jQuery.each(jQuery('[data-area-name="mobile-aux"] [data-child] a'), function (e) {
		var $this = $(this);

		$this.on('click', function () {
			$('body').removeClass('nav-open');
			$('[data-role="nav-body"]').removeClass('deployed');
			$('[data-role="nav-deployer"]').removeClass('deployed');
		});
	});

	jQuery.each(jQuery('[data-role="video-player"]'), function () {
		var $this_video = $(this);
		var video = this;
		var $deployer = $($this_video.siblings()).find('[data-role="video-player-deployer"]');

		$deployer.on("click", function () {
			var $video_prev = $this_video.siblings('[data-role="video-prev"]');

			if (video.paused == true) {
				video.play();
				$deployer.addClass('playing');
				$this_video.attr('controls', 'true');
				$video_prev.addClass('hide').removeClass('paused');
			} else {
				video.pause();
				$video_prev.addClass('paused').removeClass('hide').removeAttr('style');
				$deployer.removeClass('playing');
				$this_video.removeAttr('controls');
			}
		});

		if (video.paused == true) {
			$deployer.addClass('check-playing');
		} else {
			$deployer.removeClass('check-playing');
		}
	});

	jQuery.each(jQuery('[data-role="divider-list"]'), function () {
		var $this = $(this),
			countext = $this.children().length,
			divider = $this.data('count-paragraph') || 2,
			this_class = $this.data('class'),
			leftcol_qant = Math.round(countext / divider),
			contador = 0,
			left_col = '',
			col_html = '',
			right_col = '';

		if (countext >= divider) {
			jQuery.each(jQuery($this.children()), function () {
				var lilkid = $(this).get(0).outerHTML;
				contador++;
				if (contador <= leftcol_qant) {
					left_col += lilkid;
				} else {
					right_col += lilkid;
				}
			});

			col_html = '<ul class="'+this_class+'">' + left_col + '</ul>' + '<ul class="'+this_class+'">' + right_col + '</ul>';
			$this.replaceWith(col_html);
		} else {
			var new_html = $this.html();
			$this.replaceWith('<ul class="'+this_class+'">' + new_html + '</ul>');
		}
	});

	jQuery.each(jQuery('[data-role="divider-row"]'), function () {
		var $this = $(this),
			$target = $this.children('[data-role="divider-target"]'),
			countext = $target.children().length,
			divider = $this.data('count-paragraph') || 2,
			split = divider / 2 || 2,
			leftcol_qant = Math.round(countext / split),
			contador = 0,
			left_col = '',
			col_html = '',
			right_col = '';

		if (countext >= divider) {
			jQuery.each(jQuery($target.children()), function () {
				var lilkid = $(this).get(0).outerHTML;
				contador++;
				if (contador <= leftcol_qant) {
					left_col += lilkid;
				} else {
					right_col += lilkid;
				}
			});

			col_html = '<td class="left">' + left_col + '</td>' + '<td class="right">' + right_col + '</td>';
			$target.replaceWith(col_html);
		} else {
			var new_html = $target.html();
			$target.replaceWith('<td>' + new_html + '</td><td></td>');
		}
	});

	jQuery.each(jQuery('[data-role="divider-grid"]'), function () {
		var $this = $(this),
			data_class = $this.data('class'),
			countext = $this.children().length,
			divider = $this.data('count-paragraph') || 2,
			split = divider / 2 || 2,
			leftcol_qant = Math.round(countext / split),
			contador = 0,
			left_col = '',
			col_html = '',
			right_col = '';

		if (countext >= divider) {
			jQuery.each(jQuery($this.children()), function () {
				var lilkid = $(this).get(0).outerHTML;
				contador++;
				if (contador <= leftcol_qant) {
					left_col += lilkid;
				} else {
					right_col += lilkid;
				}
			});

			col_html = '<div class="' + data_class + '">' + left_col + '</div>' + '<div class="' + data_class + '">' + right_col + '</div>';
			$this.html(col_html);
		} else {
			var new_html = $this.html();
			$target.html('<div class="' + data_class + '">' + new_html + '</td>');
		}
	});

	// jQuery.each(jQuery('[data-module="common-form"][data-validation="generic_submit"]'), function () {
	// 	console.log('common-form');
	// 	var $form = $(this),
	// 	$parent = $($form.parents('[data-parent]')) || $($form.parent('div'));
	// 	$form.submit(function(event){
	// 		console.log('submittin');
	// 		$parent.addClass('sending');
	// 	})
	// });

	jQuery.each(jQuery('[data-role="floating-footer"]'), function () {
		var $hidden = $(this),
			$holder = $(this).parents('[data-role="floating-footer-holder"]'),
			$limit_top = $('#top').height();
		
		$holder.attr('style', 'height: ' + $hidden.height() + 'px');
		
		$(window).scroll( function () {
			var scroll = $(window).scrollTop();
         var stop = $holder.offset().top - ($limit_top * 1.5);

			if(scroll >= $limit_top && scroll <= stop){
				$hidden.addClass('active');
			}else{					
				$hidden.removeClass('active');
			}
		});
	});

	jQuery.each(jQuery('[data-role="peekaboo"]'), function () {
		var $seeker = $(this);
		var $player = $('[data-name="peekaboo-player"]');

		console.log($player);

		$seeker.on("click", function () {
			var $message_active = $seeker.html();

			$player.toggleClass('wait');

			if ($seeker.html() === $seeker.data('less')) {
				$seeker.html($seeker.data('more'));
			} else {
				$seeker.html($seeker.data('less'));
			}
		});
		
	});

	jQuery.each(jQuery('img[data-srcset]'), function () {
		var $this_img = $(this);
		var src_set = $this_img.data('srcset') || '';

		if(src_set !== ''){
			var sizes = src_set.split(',');
			var resize = [];
			var default_src = $this_img.attr('src');

			if(sizes.length > 0){
				$this_img.attr('data-srcfull', default_src);
				sizes.forEach(function (value, key) {
					value = value.slice(0,-1);
					var size = value.split(' ');

					resize.push({ size: size[1], src: size[0] })
				});
				resize.reverse();
				resize.forEach(function (fuente, key) {
					var window_width = $(window).width();
					if(window_width <= fuente['size']){ $this_img.attr('src', fuente['src']) }
					if(window_width > resize[0]['size']){ $this_img.attr('src', default_src) }
				});
				$(window).on('resize', function () {
					resize.forEach(function (fuente, key) {
						var window_width = $(window).width();
						if(window_width <= fuente['size']){ $this_img.attr('src', fuente['src']) }
						if(window_width > resize[0]['size']){ $this_img.attr('src', default_src) }
					});
				});
			}
		}
	});

	jQuery.each(jQuery('[data-srcset]:not(img)'), function () {
		var $this_figure = $(this);
		var src_set = $this_figure.data('srcset') || '';

		console.log(src_set);

		if(src_set !== ''){
			var sizes = src_set.split(',');
			var resize = [];
			var default_src = $this_figure.attr('style');
			
			if(sizes.length > 0){
				$this_figure.attr('data-srcfull', default_src);
				sizes.forEach(function (value, key) {
					value = value.slice(0,-1);
					var size = value.split(' ');

					resize.push({ size: size[1], bg: size[0] })
				});
				resize.reverse();
				resize.forEach(function (fuente, key) {
					var window_width = $(window).width();
					if(window_width <= fuente['size']){ $this_figure.attr('style', fuente['bg']) }
					if(window_width > resize[0]['size']){ $this_figure.attr('style', default_src) }
				});
				$(window).on('resize', function () {
					resize.forEach(function (fuente, key) {
						var window_width = $(window).width();
						if(window_width <= fuente['size']){ $this_figure.attr('style', fuente['bg']) }
						if(window_width > resize[0]['size']){ $this_figure.attr('style', default_src) }
					});
				});
			}
		}
	});

	jQuery.each(jQuery('pre'), function () {
		var $this = $(this);
		
		$this.removeAttr('class').addClass('prettyprint');
		prettyPrint();
	});
});